import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, Outlet, useParams } from 'react-router-dom';

function Home() {
  return (
    <div style={{ padding: 20 }}>
      <h2>QR Demo Seite für SUT</h2>

      <div style={{ backgroundColor: '#e8e8e8', padding: 10 }}>
        <Info />
        <h3>Todo</h3>
        <ul>
          <li>Add a bit of text (maybe include 'about' here)</li>
          <li>Implement '/qr/:serial' pages</li>
        </ul>
      </div>
    </div>
  );
}
function Info() {
  return (
    <div>
      <p>Diese kleine Demo zeigt, was man mit QR Codes machen kann.</p>

      <nav style={{ marginTop: 10 }}>
        Demo Links:
        <Link to="/" style={{ padding: 5 }}>
          (Home)
        </Link>
        <Link to="/qr/411.45.678" style={{ padding: 5 }}>
          /qr/411.45.678
        </Link>
        <Link to="/qr/415.45.678" style={{ padding: 5 }}>
          /qr/415.45.678
        </Link>
        <Link to="/qr/123.45.678" style={{ padding: 5 }}>
          /qr/123.45.678
        </Link>
      </nav>

      <p>Kontakt</p>
      <p>
        Hannes Strautmann<br/>
        <a href="mailto:hannes@strautmann.dev">hannes@strautmann.dev</a><br/>
        <a href="https://strautmann.dev/kontakt/">Weitere Kontaktdaten</a><br/>
        <a href="https://strautmann.dev/">https://strautmann.dev/</a>
      </p>
    </div>
  );
}
function QrWrapper() {
  return <Outlet />
}
function ProductImage({ serial }) {
  const fileName = serial.startsWith('411') ? 'alb-ready'
                  : serial.startsWith('415') ? 'alb'
                  : 'elmo';
  const altText = serial.startsWith('411') ? 'Auto-Load Baler ready'
                  : serial.startsWith('415') ? 'Auto-Load Baler'
                  : 'Elmo';
  return <img src={ `/${ fileName }.png` } width={ 200 } alt={ altText } />
}
function ProductNameShort({ serial }) {
  return serial.startsWith('411') ? 'ALB ready'
          : serial.startsWith('415') ? 'ALB'
          : 'Elmo';
}
function ProductAddress({ serial }) {
  return serial.startsWith('411') ? 'Auf dem Haarkamp 22, Glandorf'
          : serial.startsWith('415') ? 'Heidestr. 9, Glandorf'
          : 'Zur Steinleite 9, Gundelsheim';
}
function ActionButton({ title, url }) {
  // TODO: replace the <button> with button-styled <a>s
  return (
    <button
      onClick={
        () => url
          ? window.location.href=url
          : alert('Jetzt würde eine Seite von SUT aufgehen...') }
      style={{
        display: 'block',
        marginTop: 10,
        padding: 5 }}
    >
      { title }
    </button>
  )
}
function Landing() {
  const { serial } = useParams();
  return (
    <div style={{ padding: 20 }}>
      <h2>Meine Strautmann Presse {serial}</h2>
      <div style={{ clear: 'both' }} />
      <div style={{ float: 'left' }}>
        <ProductImage serial={ serial } />
      </div>
      <div style={{ float: 'left', marginLeft: 30 }}>
        <div>Modell: <ProductNameShort serial={ serial } /> (wird nur nach Passwort angezeigt)</div>
        <div>Adresse: <ProductAddress serial={ serial } /> (wird nur nach Passwort angezeigt)</div>
        <ActionButton title="Verbrauchsmaterial bestellen" />
        <ActionButton title="Strautmann PSI" url={`https://psi.strautmann.cloud/maschinen/${serial}`} />
        <ActionButton title="Bedienungsanleitung ansehen" />
        <ActionButton title="Wartungstermin vereinbaren" />
        <ActionButton title="Problem melden" />
        <div style={{ marginBottom: 10 }} /> 
      </div>
      <div style={{ clear: 'both' }} />

      <div style={{ backgroundColor: '#e8e8e8', marginTop: 40, padding: 10 }}>
        <h3>Über diese Seite</h3>
        (Die Pressen-Seite wird erst nach einem Login angezeigt, weil für alle weiteren Aktionen sowieso ein Passwort benötigt wird.)

        <Info />
      </div>
    </div>
  )
}

function App() {
  return <Router>
    <nav style={{ margin: 10 }}>
      <img height={ 50 } style={{ marginBottom: -25, backgroundColor: '#fff', paddingRight: 60 }} src="/logo.png" alt="Logo von Strautmann Umwelttechnik" />
    </nav>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/qr/" element={<Home />} />
      <Route path="qr" element={<QrWrapper />}>
        {/* <Route index element={<Home />} /> */}
        <Route path=":serial" element={<Landing />} />
      </Route>
    </Routes>
  </Router>;
}

export default App;
